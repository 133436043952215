.App {
  text-align: center;
}

body{
  font-family: 'Nunito', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rocketIcons{
  width: 30px;
  height: 30px;
  display: inline;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blinking {
  from {
    color: rgb(15 118 110 / var(--tw-text-opacity));
  }
  to {
    color: transparent;
  }
}

.block {
  display: block;
}

.cursor {
  animation: blinking 0.8s infinite;
}


.fancy {
  line-height: 0.5;
  text-align: center;
}
.fancy span {
  display: inline-block;
  position: relative;  
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  top: 0;
  /* width: 600px; */
  width: 200%;
}
.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&family=Nunito:ital,wght@0,500;0,600;0,800;1,600;1,700&family=Source+Sans+Pro:wght@300&display=swap');